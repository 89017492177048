import Vue from 'vue';
import VueRouter from 'vue-router';
// import store from '../store/index';
// import Home from '@/views/home/Home.vue';
// import Login from '@/views/login/Login.vue';
// import SendApi from '@/views/sendApi/SendApi.vue';
// import Register from '@/views/register/Register.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/Home.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/Login.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/send-api',
    name: 'send',
    component: () => import('@/views/sendApi/SendApi.vue'),
    meta: {
      public: false,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/register/Register.vue'),
    meta: {
      public: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

// eslint-disable-next-line consistent-return
// router.beforeEach((routeTo, routeFrom, next) => {
//   const user = localStorage.getItem('user');
//   if (!routeTo.meta.public && !user) {
//     console.log(user);
//     return next({ path: '/login' });
//   }
//   next();
//   // to and from are both route objects. must call `next`.
// });

export default router;
