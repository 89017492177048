<template>
  <div class="login__main">
    <h1>Login</h1>
    <form @submit.prevent="userLogin" action="" class="login__form">
      <label>Email</label>
      <input
        v-model="loginData.username"
        placeholder="Nome de usuário"
        class="login__input_email"
        type="text"
      />
      <label>Senha</label>
      <input
        placeholder="Digite sua senha"
        class="login__input_senha"
        type="password"
        v-model="loginData.password"
      />
      <div class="login__forgot_div">
        <div class="login__checkbox_div">
          <input type="checkbox" id="remember" />
          <label class="login__label_remember" for="remember">Lembrar-me</label>
        </div>
        <p class="login__forgot_password">Esqueceu a senha?</p>
      </div>
      <button type="submit" class="login__enviar">Enviar</button>
      <div class="login__register_div">
        <p>Não tem uma conta?</p>
        <p class="login__link_register">Cadastre-se</p>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loginData: {
        username: '',
        password: '',
      },
    };
  },
  methods: {
    userLogin() {
      this.$store
        .dispatch('userLogin', this.loginData)
        .then(() => {
          this.$router.push({ name: 'Home' });
        })
        .catch((err) => {
          if (err.request.status === 401 || err.request.status === 404) {
            this.showAlertError();
          }
        });
    },

    showAlertError() {
      // Use sweetalert2
      this.$swal({
        icon: 'error',
        title: 'Oops...',
        confirmButtonColor: '#DA1E28',
        text: 'Login ou senha inválido(s), tente novamente',
        footer: '<a href="https://dopster.io/#contact">Se persistir, contate-nos</a>',
      });
    },

    sendLogin() {
      this.$store.commit('SET_FILTER_SEARCH', this.loginData);
      console.log('Rodou');
    },
    // teste() {
    //   axios.get('http://localhost:3000/language/getAll').then((res) => {
    //     console.log(res);
    //   }).catch((err) => {
    //     console.log(err);
    //   });
    // },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
