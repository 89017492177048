import axios from 'axios';
import { Promise } from 'core-js';
import Vue from 'vue';
import Vuex from 'vuex';
// eslint-disable-next-line no-unused-vars
import baseAPI from '../services/getAllApis';
// import sendApi from '../services/sendApi';

Vue.use(Vuex);
const nameUsuario = JSON.parse(localStorage.getItem('username'));
export default new Vuex.Store({
  state: {
    // data
    allApis: [],
    allApisFiltred: [],
    sendApi: {},
    loginData: {},
    id: null,
    user: false,
    nameUser: nameUsuario,
  },
  getters: {
    allApis: (state) => state.items,
    // userLogado: (state) => {
    //   if (state.user !== null) {
    //     console.log('Logou');
    //     return true;
    //   }
    //   console.log('deslogou');
    //   return false;
    // },
  },

  mutations: {
    SET_APIS(state, payload) {
      state.allApis = payload;
    },
    SET_FILTER_SEARCH(state, payload) {
      state.allApis = payload;
    },
    SET_LOGIN_DATA(state, { id, loginData }) {
      state.id = id;
      state.loginData = loginData;
      state.nameUser = loginData.username;
    },
    LogOut(state) {
      localStorage.removeItem('user');
      state.user = null;
      state.id = null;
      state.loginData = {};
    },
    // SET_SEND_API(state, payload) {
    //   state.sendApi = payload;=
    // },
  },
  actions: {
    userLogin({ commit }, loginData) {
      return new Promise((resolve, reject) => {
        axios.post('http://localhost:8080/api/auth/signin', loginData)
          .then((res) => {
            commit('SET_LOGIN_DATA', {
              id: res.data.id,
              loginData: res.data,
            });
            this.state.user = res.data.id;
            localStorage.setItem('user', JSON.stringify(res.data.id));
            localStorage.setItem('username', JSON.stringify(res.data.username));
            resolve(res.data);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    loadIApis({ commit }) {
      baseAPI.get('/project/getAllProject')
        .then((res) => res.data)
        .then((allApis) => {
          console.log(allApis);
          commit('SET_APIS', allApis);
        }).catch((err) => {
          console.log(err);
        });
    },
    // sendApis({ commit }) {
    //   sendApi.post('project/postProject',
    //     {
    //       ProjectName: this.userRegistration.name,
    //       ProjectGit: this.userRegistration.linkGit,
    //       ProjectDocumentation: this.userRegistration.ProjectDocumentation,
    //       Lang_id: 2,
    //       UserId: this.userRegistration.userId,
    //       Cat_id: 2,
    //       Proj_Description: this.userRegistration.Proj_Description,
    //     })
    //     .then((res) => {
    //       commit('SET_SEND_API', res);
    //     }).catch((err) => {
    //       console.log(err);
    //     });
    // },
  },
});

// sendDataUser() {
//   sendApi
//     .post('project/postProject', {
//       ProjectName: this.userRegistration.name,
//       ProjectGit: this.userRegistration.linkGit,
//       ProjectDocumentation: this.userRegistration.ProjectDocumentation,
//       Lang_id: 2,
//       UserId: this.userRegistration.userId,
//       Cat_id: 2,
//       Proj_Description: this.userRegistration.Proj_Description,
//     })
//     .then((res) => {
//       console.log(res);
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// },
