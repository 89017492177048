<template>
  <div class="fab">
    <button class="main"></button>
    <ul>
      <li>
        <label for="opcao1">Opção 1</label>
        <button @click="leaveToHome" id="opcao1"><img src="@/assets/home.png" alt="" /></button>
      </li>
      <li>
        <label for="opcao2">Opção 2</label>
        <button @click="leaveToSendApi" id="opcao2">+</button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  methods: {
    leaveToSendApi() {
      this.$router.push({ path: '/send-api' });
    },
    leaveToLogin() {
      this.$router.push({ path: '/login' });
    },
    logoutUser() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      const path = '/';
      if (this.$route.path !== path) {
        this.$router.push({ path: '/' });
      }
      return this.$store.commit('LogOut');
    },
    leaveToHome() {
      const path = '/';
      if (this.$route.path !== path) {
        this.$router.push({ path: '/' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
