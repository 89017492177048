<template>
  <div class="header__container">
    <div><img class="header__logo" src="@/assets/DopsterLogoCor.png" alt="" /></div>
    <div class="header__buttons">
      <div class="header__list_desktop">
        <router-link class="header__links" to="/send-api"
          ><p>Enviar API</p></router-link
        >
        <router-link class="header__links" to="/"><p>Home</p></router-link>
        <a href="dopster.io" class="header__links"><p>Dopster</p></a>
      </div>
      <div class="tooltip">
        <img class="header__notification" src="@/assets/notification.svg" alt="" />
        <span class="tooltiptext">Em Breve</span>
      </div>
      <img class="header__favorite" src="@/assets/favorite.svg" alt="" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
