<template>
  <div id="app">
    <NavBar />
    <router-view />
    <floatButton class="float__button"/>
  </div>
</template>

<script>
import NavBar from '@/components/shared/navbar/NavBar.vue';
import store from './store/index';
import floatButton from './components/shared/floatButton/floatButton.vue';

export default {
  components: {
    NavBar,
    floatButton,
  },
  mounted() {
    const user = localStorage.getItem('user');
    store.state.user = user !== undefined && user !== null;
  },
};
</script>

<style lang="scss">
@import './global/buttons-mixin.scss';
@import './global/colors.scss';

#app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100vw;
}

@media screen and (min-width : 769px) {
 .float__button {
   display: none;
 }
}
</style>
