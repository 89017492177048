<template>
  <div class="header__container">
    <div><img class="header__logo" src="@/assets/DopsterLogoCor.png" alt="" /></div>
    <div class="header__buttons">
      <div class="header__list_desktop">
        <a class="header__link" href="https://dopster.io/"><p>Dopster</p></a>
        <button @click.prevent="logoutUser" class="header__logout">
          Logout
        </button>
        <router-link class="header__link" to="/"><p>Home</p></router-link>
        <a class="header__link" href="http://localhost:8081/send-api"><p>Enviar API</p></a>
      </div>
      <img class="header__favorite" src="@/assets/favorite.svg" alt="" />
      <img class="header__notification" src="@/assets/bell.png" alt="" />
      <!-- <div class="header__icon_image">
        <img class="header__user_image" src="@/assets/userIcon.png" alt="">
      </div> -->
      <!-- <img class="header__menu" src="@/assets/menuIcon.svg" alt=""> -->
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    logoutUser() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      const path = '/';
      if (this.$route.path !== path) {
        this.$router.push({ path: '/' });
      }
      return this.$store.commit('LogOut');
    },
  },
};
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
