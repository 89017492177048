<script>
import CardApi from '@/components/shared/card/CardApi.vue';
import baseAPI from '@/services/getAllApis';
import store from '../../store/index';

export default {
  name: 'Home',
  data() {
    return {
      numberApi: 0,
      search: '',
      stepPagesApi: 1,
      categorys: null,
      modal: false,
      username: this.userName,
      ratingArray: [],
    };
  },
  components: {
    CardApi,
  },
  computed: {
    userName() {
      const name = store.state.loginData.nameUser;
      return name[0].toUpperCase() + name.substring(1);
    },
    allApis() {
      return store.state.allApis;
    },
    pagesApi() {
      const newPageArray = this.itensFilterByCategory.slice(
        9 * (this.stepPagesApi - 1),
        9 * this.stepPagesApi,
      );
      return newPageArray;
    },
    itensFiltered() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.stepPagesApi = 1;
      let valores = [];
      valores = store.state.allApis.filter(
        (item) => item.projectname?.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          || item.proj_description?.toLowerCase().indexOf(this.search.toLowerCase()) > -1,
      );
      return valores;
    },
    itensFilterByCategory() {
      // let filteredArray = [];
      if (this.categorys !== null) {
        const newArrayCategory = this.itensFiltered.filter(
          (element) => element.category_id === Number(this.categorys),
        );
        return newArrayCategory;
      }
      return this.itensFiltered;
    },
    apisNumber() {
      return this.itensFiltered.length;
    },
  },
  methods: {
    // DELETE FILTERS

    deleteFilter() {
      this.categorys = null;
    },
    // RATING
    testeRobinRequest() {
      baseAPI
        .get('project/getProjectRating')
        .then((res) => {
          console.log(res);
          this.ratingArray = JSON.parse(JSON.stringify(res.data));
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // modal

    openModal() {
      if (this.modal === true) {
        this.modal = false;
      } else {
        this.modal = true;
      }
    },

    // Pagination
    previousPage() {
      if (this.stepPagesApi > 1) {
        this.stepPagesApi -= 1;
      }
    },
    NextPage() {
      if (this.pagesApi.length >= 9 && this.itensFiltered.length / (this.stepPagesApi * 9) > 1) {
        this.stepPagesApi += 1;
      }
    },
    // Number Apis in page
    numberApis() {
      return this.allApis.length();
    },
    // Filter
  },
  mounted() {
    this.testeRobinRequest();
    this.$store.dispatch('loadIApis');
  },
};
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>

<template>
  <div class="home__container">
    <div class="home__boxcolor"></div>
    <div class="home__main">
      <div class="whitebox__container">
        <div class="whitebox__filter-result">
          <div class="whitebox_container_input">
            <input
              type="search"
              class="whitebox_search"
              v-model="search"
              placeholder="Digite aqui"
            />
            <img src="../../assets/scope.svg" alt="" />
          </div>
        </div>
        <div class="whitebox__options">
          <button class="whitebox__button">
            <img class="whitebox__organization" src="@/assets/icon-order.svg" alt="" />
          </button>
          <button @click="openModal" class="whitebox__filter">
            <img class="whitebox__engine" src="@/assets/icon-filter.svg" alt="" />
          </button>
          <div v-if="this.$store.state.user" class="whitebox__user_div">
            <div class="whitebox__user"></div>
            <p class="whitebox__username">
              {{ $store.state.nameUser[0].toUpperCase() + $store.state.nameUser.substring(1) }}
            </p>
          </div>
        </div>
      </div>
      <div class="result-container">
        <transition name="fade">
          <div class="home__filter_modal" v-if="modal">
            <br />
            <h2 class="home__title_modal">Fltros</h2>
            <div class="home__cat_modal">
              <div>
                <!-- <h4>Categoria</h4> -->
                <!-- <br /> -->
                <input
                  class="modal__space_input"
                  type="radio"
                  value="2"
                  id="finance"
                  v-model="categorys"
                />
                <label for="finance">Finance</label>
              </div>
              <div>
                <input
                  class="modal__space_input"
                  type="radio"
                  value="3"
                  id="Art"
                  v-model="categorys"
                />
                <label for="Art">Art</label>
              </div>
              <div>
                <input
                  class="modal__space_input"
                  type="radio"
                  value="4"
                  id="Location"
                  v-model="categorys"
                />
                <label for="Location">Location</label>
              </div>
              <div>
                <input
                  class="modal__space_input"
                  type="radio"
                  value="5"
                  id="Malling"
                  v-model="categorys"
                />
                <label for="Malling">Malling</label>
              </div>
            </div>
            <button @click="deleteFilter">LIMPAR</button>
          </div>
        </transition>
        <br />
        <br />
        <section class="filter-result-subtitle">
          <h1 class="home__title-search">Resultados da pesquisa: {{ search }}</h1>
          <p class="home__number-search">{{ apisNumber }} API’S encontradas</p>
        </section>
        <div id="center-api-list">
          <div class="page-apis">
            <CardApi
              class="card-api"
              v-for="(apis, index) in pagesApi"
              v-bind:key="index"
              :ratingArray="ratingArray"
              :swaggerLink="apis.projectdocumentation"
              :githubLink="apis.projectgit"
              :title="apis.projectname"
              :description="apis.proj_description"
              :image="apis.img_lang"
              :cardId="apis.projectid"
            />
          </div>
        </div>
        <section class="pagination-apis">
          <button @click="previousPage">&lt;</button>
          <h3>{{ stepPagesApi }}</h3>
          <button @click="NextPage">&gt;</button>
        </section>
      </div>
    </div>
  </div>
</template>
