<template>
  <nav class="nav__div">
    <NavBarLogado class="nav__logado" v-if="$store.state.user" />
    <NavBarDeslogado class="nav__deslogado" v-else />
  </nav>
</template>

<script>
import NavBarLogado from '@/components/shared/navbarLogado/NavBarLogado.vue';
import NavBarDeslogado from '@/components/shared/navbarDeslogado/NavBarDeslogado.vue';

export default {
  components: {
    NavBarLogado,
    NavBarDeslogado,
  },
};
</script>

<style lang="scss" scoped>
@import './style.scss'
</style>
