<template>
  <div class="send__container">
    <h2>Envie sua API</h2>
    <form action="" class="send__form" @submit.prevent="sendDataUser">
      <div class="send__div_form">
        <label for="name">Nome</label>
        <input
          class="send__input_normal"
          type="text"
          placeholder="Name do projeto"
          name=""
          id="name"
          v-model="userRegistration.name"
        />
      </div>
      <div class="send__div_form">
        <label for="projectgit">Link git</label>
        <input
          class="send__input_normal"
          type="text"
          placeholder="Link do projeto"
          name=""
          id="projectgit"
          v-model="userRegistration.linkGit"
        />
      </div>
      <div class="send__div_form">
        <label for="projectDocumentation">Link da documentação</label>
        <input
          class="send__input_normal"
          type="text"
          placeholder="Documentação do projeto"
          name=""
          id="projectDocumentation"
          v-model="userRegistration.linkDocumentation"
        />
      </div>

      <div class="send__options_div">
        <div class="send__div_form send__language_div">
          <label for="langPr_id">Linguagem</label>
          <select name="" id="" v-model="userRegistration.language" class="send__selected_input">
            <option value="1">Javascriot</option>
            <option value="2">Phyton</option>
            <option value="3">Ruby</option>
            <option value="4">PHP</option>
            <option value="5">Java</option>
            <option value="6">C#</option>
            <option value="7">Kotlin</option>
            <option value="8">Scala</option>
          </select>
        </div>
        <div class="send__div_form send__category_div">
          <label for="Cat_id">Categoria</label>
          <select v-model="userRegistration.category" name="" id="" class="send__selected_input">
            <option>Categoria</option>
            <option value="5">Malling</option>
            <option value="2">Finance</option>
            <option value="3">Art</option>
            <option value="1">Weather</option>
          </select>
        </div>
      </div>
      <div class="send__div_form">
        <label for="proj_Description">Descrição do projeto</label>
        <textarea
          class="send__textarea_input"
          name=""
          id=""
          cols="50"
          rows="6"
          v-model="userRegistration.descriptionProject"
        ></textarea>
      </div>
      <button class="send__button" type="submit">Enviar</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
// import sendApi from '../../services/sendApi';

export default {
  data() {
    return {
      userRegistration: {
        name: '',
        linkGit: '',
        linkDocumentation: '',
        language: null,
        category: null,
        descriptionProject: '',
        // userId: localStorage.getItem('user'),
      },
    };
  },
  methods: {
    showAlertDone() {
      // Use sweetalert2
      this.$swal({
        icon: 'success',
        title: 'Sucesso!',
        text: 'API enviada com sucesso!',
        confirmButtonColor: '#198038',
      });
    },
    sendDataUser() {
      axios
        .post('https://hub-api-dev.dopster.io/project/postProject', {
          ProjectName: this.userRegistration.name,
          ProjectGit: this.userRegistration.linkGit,
          ProjectDocumentation: this.userRegistration.linkDocumentation,
          Lang_id: this.userRegistration.language,
          // eslint-disable-next-line quotes
          UserId: "631ae090-6f57-4950-a0f5-f7bd1c69ecc9",
          Cat_id: this.userRegistration.category,
          Proj_Description: this.userRegistration.ProjectDocumentation,
        })
        .then((res) => {
          this.showAlertDone();
          console.log(res);
        })
        .catch((err) => {
          // console.log(this.userRegistration);
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
// http://localhost:3000/api-docs/#/ProjectLanguage/get_language_getAll OBTER IDIOMAS PARA O V-FOR
@import './style.scss';
</style>
