<template>
  <div class="card__container">
    <section class="section__header">
      <h3>{{ title }}</h3>
      <img class="card__avatar" :src="image" alt="" />
      <!-- COLOCAR O :SRC="IMAGE" dps -->
    </section>

    <section class="section__description">
      <p>{{ description }}</p>
    </section>

    <section class="section__rating">
      <div class="card__links">
        <a class="card__link" :href="swaggerLink"><span class="card__swagger">swagger</span></a>
        <a class="card__link" :href="githubLink"><span class="card__github">github</span></a>
      </div>
      <!-- <div v-else>
        <p><strong>Faça login</strong></p>
      </div> -->
      <!-- <section class="star__section" v-if="!cardRated(cardId)">
        <div class="rating__number">
          <star-rating
            @rating-selected="setRating"
            v-bind:show="(postRatingData.ProjectId = cardId)"
            :rating="0"
            :show-rating="false"
            class="card__stars star__button"
            :star-size="15"
          />
          <p>0</p>
        </div>
      </section> -->
      <!-- <section v-for="rating in ratingArray" :key="rating.projectid">
        <div class="rating__number">
          <star-rating
            v-bind:show="(postRatingData.ProjectId = rating.projectid)"
            @rating-selected="setRating"
            v-if="rating.projectid === cardId"
            :rating="Number(rating.media)"
            :show-rating="false"
            class="card__stars star__button"
            :star-size="15"
          />
          <p>
            {{ rating.projectid == cardId ? rating.total : null }}
          </p>
        </div>
      </section> -->
    </section>
  </div>
</template>

<style lang="scss" scoped>
@import './style.scss';
</style>

<script>
// import StarRating from 'vue-star-rating';
// import baseAPI from '@/services/getAllApis';

export default {
  components: {
    // StarRating,
  },
  data() {
    return {
      // rating: '',
      // votes: '',
      // allRatings: this.ratingArray,
      // postRatingData: {
      //   ProjectId: null,
      //   Rating: null,
      //   UserId: '53b2b0ba-9ea7-4ea8-bdad-3e565e54a618',
      // },
    };
  },
  methods: {
    // showAlertError() {
    //   // Use sweetalert2
    //   this.$swal({
    //     icon: 'success',
    //     title: 'Obrigado pelo seu voto',
    //     confirmButtonColor: '#198038',
    //     text: `Seu voto de ${this.postRatingData.Rating} stars foi contabilizado`,
    //     footer: '<a>Em breve podera votar novamente :D </a>',
    //   });
    // },

    // setRating(rating) {
    //   this.postRatingData.Rating = rating;
    //   this.putRating();
    //   // this.putRating();
    //   // this.postRating();
    // },

    setIdCard() {},

    // postRating() {
    //   baseAPI
    //     .post('ratings/postRating', this.postRatingData)
    //     .then((res) => {
    //       console.log(res);
    //     })
    //     .catch((err) => {
    //       this.showAlertError();
    //       console.log(err);
    //     });
    // },
    // putRating() {
    //   baseAPI
    //     .put('ratings/putRating', this.postRatingData)
    //     .then((res) => {
    //       this.showAlertError();
    //       console.log(res);
    //     })
    //     .catch((err) => {
    //       this.showAlertError();
    //       console.log(err);
    //     });
    // },
    // cardRated(id) {
    //   const teste = this.ratingArray.some((element) => element.projectid === id);
    //   return teste;
    // },
    // getStars() {
    //   const cardFiltered = this.allRatings.filter(
    //     (projectObject) => projectObject.projectid === this.cardId,
    //   );
    //   return cardFiltered;
    // },
    // teste() {
    //   baseAPI
    //     .get('project/getProjectRating')
    //     .then((res) => {
    //       this.cards = 1;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
  },
  computed: {},
  props: ['title', 'description', 'image', 'githubLink', 'swaggerLink', 'cardId', 'ratingArray'],
  mounted() {
    // this.teste();
    // this.getStars(this.cardId);
  },
};
</script>
