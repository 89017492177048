<template>
  <div class="register__main">
    <h1>Cadastre-se</h1>
    <form @submit.prevent="registerUser" class="register__form">
      <label>Username</label>
      <input
        v-model="registerData.username"
        placeholder="Username"
        class="register__inputs register__input_username"
        type="text"
      />
      <label>Email</label>
      <input
        v-model="registerData.email"
        placeholder="Digite seu email"
        class="register__inputs register__input_email"
        type="email"
      />
      <label>Senha</label>
      <input
        v-model="registerData.password"
        placeholder="Digite sua senha"
        class="register__inputs register__input_senha"
        type="password"
      />
      <button class="register__send" type="submit">{{ buttonMoment }}</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      buttonMoment: 'Cadastrar',
      registerData: {
        username: '',
        email: '',
        role: 1,
        password: '',
      },
    };
  },
  methods: {
    registerUser() {
      this.buttonMoment = 'AGUARDE...';
      axios
        .post('http://localhost:8080/api/auth/signup', this.registerData)
        .then((res) => {
          this.showAlertTime();
          this.registerData = {};
          // this.showAlertDone();
          this.buttonMoment = 'Cadastrar';
          console.log(res);
        })
        .catch((err) => {
          this.showAlertError();
          this.buttonMoment = 'Cadastrar';
          console.log(err);
        });
    },
    showAlertError() {
      // Use sweetalert2
      this.$swal({
        icon: 'error',
        title: 'Oops...',
        confirmButtonColor: '#DA1E28',
        text: 'Usuário já existe! Verifique suas informações e tente novamente!',
        footer: '<a href="https://dopster.io/#contact">Se persistir, contate-nos</a>',
      });
    },
    showAlertDone() {
      // Use sweetalert2
      this.$swal({
        icon: 'success',
        title: 'Sucesso!',
        text: 'Cadastro efetuado com sucesso!',
        confirmButtonColor: '#198038',
        footer: '<a href="http://localhost:8081/login">Clique aqui para Logar</a>',
      });
    },
    showAlertTime() {
      // Use sweetalert2
      let timerInterval;
      this.$swal({
        title: 'Finalizando cadastro!',
        html: 'Finalizara em alguns <b></b> milliseconds.',
        timer: 1500,
        timerProgressBar: true,
        didOpen: () => {
          timerInterval = setInterval(() => {}, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then(() => {
        this.showAlertDone();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
