import axios from 'axios';

const baseAPI = axios.create({
  // baseURL: 'http://localhost:3000/',
  baseURL: 'https://hub-api-dev.dopster.io/',

  headers: {
    Accept: 'application/json',
    Content: 'application/json',
  },
});

export default baseAPI;
